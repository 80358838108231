import { Affiliate } from '../interfaces/Affiliate';

export const AFFILIATES: Affiliate[] = [
  { path: 'leussink', logo: '/assets/images/partners/leussink-logo.png' },
  { path: 'soteria', logo: '/assets/images/partners/soteria-logo.webp' },
  { path: 'dhfm', logo: '/assets/images/partners/dhfm-logo.webp' },
  { path: 'advies-van-rutger', logo: '/assets/images/partners/adviesvanrutger-logo.jpg' },
  { path: 'envida', logo: '/assets/images/partners/envida-logo.jpg' },
  { path: 'hypotheek-visie-westland', logo: '/assets/images/partners/hypotheek-logo.webp' },
  { path: 'cijfer-advies', logo: '/assets/images/partners/cijfer-advies-logo.jpg' },
  { path: 'eko-notaris', logo: '/assets/images/partners/eKoNotaris-logo.png' },
  { path: 'oosterman', logo: '/assets/images/partners/oosterman-logo.webp' },
  { path: 'romilly', logo: '/assets/images/partners/romilly-logo.webp' },
  { path: 'de-drie-advies', logo: '/assets/images/partners/de-drie-advies-logo.webp' },
  { path: 'strict-personal', logo: '/assets/images/partners/strict-personal-logo.webp' },
  { path: 'buiten-afscheid', logo: '/assets/images/partners/buiten-afscheid-logo.webp' },
  { path: 'vrijhoeven', logo: '/assets/images/partners/vrijhoeven-logo.webp' },
  { path: 'floris', logo: '/assets/images/partners/floris-logo.webp' },
  { path: 'horizons', logo: '/assets/images/partners/horizons-logo.webp' },
  { path: 'ifa', logo: '' },
  { path: 'fides', logo: '/assets/images/partners/fides-logo.webp' },
  { path: 'van-den-brandt', logo: '/assets/images/partners/van-den-brandt-logo.webp' },
  { path: 'hp-verzekeringen', logo: '/assets/images/partners/verzekeringen-logo.webp' },
  { path: 'schuurman', logo: '/assets/images/partners/schuurman-logo.webp' },
  { path: 'verburg', logo: '/assets/images/partners/verburg-logo.webp' },
  { path: 'bakker', logo: '/assets/images/partners/bakker-logo.webp' },
  { path: 'hypotheek-visie-meppel', logo: '/assets/images/partners/hypotheek-logo.webp' },
];
