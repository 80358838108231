/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NLOnlyGuardService } from 'src/app/features/language/guards/nl-only-guard.service';
import { FrontPagesComponent } from './front-pages.component';

const routes: Routes = [
  {
    path: '',
    component: FrontPagesComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('../home-page/home-page.module').then((m) => m.HomePageModule),
      },
      {
        path: 'why-life-after-me',
        loadChildren: () =>
          import('../why-lam-page/why-lam-page.module').then((m) => m.WhyLamPageModule),
      },
      {
        path: 'how-it-works',
        loadChildren: () =>
          import('../how-it-works-page/how-it-works-page.module').then(
            (m) => m.HowItWorksPageModule,
          ),
      },
      {
        path: 'security',
        loadChildren: () =>
          import('../security-page/security-page.module').then((m) => m.SecurityPageModule),
      },
      {
        path: 'contact',
        loadChildren: () =>
          import('../contact-page/contact-page.module').then((m) => m.ContactPageModule),
      },
      {
        path: 'about',
        loadChildren: () => import('../team-page/team-page.module').then((m) => m.TeamPageModule),
      },
      {
        path: 'faq',
        loadChildren: () => import('../faq-page/faq-page.module').then((m) => m.FaqPageModule),
      },
      {
        path: 'business',
        loadChildren: () =>
          import('../business-page/business-page.module').then((m) => m.BusinessPageModule),
      },
      {
        path: 'privacy-policy',
        loadChildren: () =>
          import('../privacy-page/privacy-page.module').then((m) => m.PrivacyPageModule),
      },
      {
        path: 'herroeping',
        canActivate: [NLOnlyGuardService],
        loadChildren: () =>
          import('../revocation-page/revocation-page.module').then((m) => m.RevocationPageModule),
      },
      {
        path: 'terms-and-conditions',
        loadChildren: () =>
          import('../terms-and-conditions-page/terms-and-conditions-page.module').then(
            (m) => m.TermsAndConditionsPageModule,
          ),
      },
      {
        path: 'complaints',
        loadChildren: () =>
          import('../complaints-page/complaints-page.module').then((m) => m.ComplaintsPageModule),
      },
      {
        path: 'try',
        loadChildren: () =>
          import('../landing-page/landing-page.module').then((m) => m.LandingPageModule),
      },
      {
        path: 'start',
        loadChildren: () =>
          import('../landing-page-2/landing-page-2.module').then((m) => m.LandingPage2Module),
      },
      {
        path: 'ifa',
        loadChildren: () =>
          import('../landing-page-ifa/landing-page-ifa.module').then((m) => m.LandingPageIfaModule),
      },
      {
        path: 'leussink',
        loadChildren: () =>
          import('../affiliates/leussink-page/leussink-page.module').then(
            (m) => m.LeussinkPageModule,
          ),
      },
      {
        path: 'cijfer-advies',
        loadChildren: () =>
          import('../affiliates/cijferAdvies-page/cijferAdvies-page.module').then(
            (m) => m.CijferAdviesPageModule,
          ),
      },
      {
        path: 'eko-notaris',
        loadChildren: () =>
          import('../affiliates/eKonotaris-page/eKonotaris-page.module').then(
            (m) => m.EkoNotarisPageModule,
          ),
      },
      {
        path: 'oosterman',
        loadChildren: () =>
          import('../affiliates/oosterman-page/oosterman-page.module').then(
            (m) => m.OostermanPageModule,
          ),
      },
      {
        path: 'hypotheek-visie-westland',
        loadChildren: () =>
          import('../affiliates/hypotheek-page/hypotheek-page.module').then(
            (m) => m.HypotheekPageModule,
          ),
      },
      {
        path: 'soteria',
        loadChildren: () =>
          import('../affiliates/soteria-page/soteria-page.module').then((m) => m.SoteriaPageModule),
      },
      {
        path: 'romilly',
        loadChildren: () =>
          import('../affiliates/romilly-page/romilly-page.module').then((m) => m.RomillyPageModule),
      },
      {
        path: 'dhfm',
        loadChildren: () =>
          import('../affiliates/dhfm-page/dhfm-page.module').then((m) => m.DHFMPageModule),
      },
      {
        path: 'advies-van-rutger',
        loadChildren: () =>
          import('../affiliates/adviesVanRutger-page/adviesVanRutger-page.module').then(
            (m) => m.AdviesVanRutgerPageModule,
          ),
      },
      {
        path: 'envida',
        loadChildren: () =>
          import('../affiliates/envida-page/envida-page.module').then((m) => m.ENVIDAPageModule),
      },
      {
        path: 'buiten-afscheid',
        loadChildren: () =>
          import('../affiliates/buiten-page/buiten-page.module').then((m) => m.BuitenPageModule),
      },
      {
        path: 'strict-personal',
        loadChildren: () =>
          import('../affiliates/strict-page/strict-page.module').then((m) => m.StrictPersonalPageModule),
      },
      {
        path: 'de-drie-advies',
        loadChildren: () =>
          import('../affiliates/deDrie-page/deDrie-page.module').then((m) => m.DeDriePageModule),
      },
      {
        path: 'vrijhoeven',
        loadChildren: () =>
          import('../affiliates/vrijhoeven-page/vrijhoeven-page.module').then((m) => m.VrijhoevenPageModule),
      },
      {
        path: 'floris',
        loadChildren: () =>
          import('../affiliates/floris-page/floris-page.module').then(
            (m) => m.FlorisPageModule,
          ),
      },
      {
        path: 'horizons',
        loadChildren: () =>
          import('../affiliates/horizons-page/horizons-page.module').then(
            (m) => m.HorizonsPageModule,
          ),
      },
      {
        path: 'fides',
        loadChildren: () =>
          import('../affiliates/fides-page/fides-page.module').then(
            (m) => m.FidesPageModule,
          ),
      },
      {
        path: 'horizons',
        loadChildren: () =>
          import('../affiliates/horizons-page/horizons-page.module').then(
            (m) => m.HorizonsPageModule,
          ),
      },
      {
        path: 'hp-verzekeringen',
        loadChildren: () =>
          import('../affiliates/verzekeringen-page/verzekeringen-page.module').then(
            (m) => m.VerzekeringenPageModule,
          ),
      },
      {
        path: 'van-den-brandt',
        loadChildren: () =>
          import('../affiliates/brandt-page/brandt-page.module').then(
            (m) => m.BrandtPageModule,
          ),
      },
      {
        path: 'schuurman',
        loadChildren: () =>
          import('../affiliates/schuurman-page/schuurman-page.module').then(
            (m) => m.SchuurmanPageModule,
          ),
      },
      {
        path: 'verburg',
        loadChildren: () =>
          import('../affiliates/verburg-page/verburg-page.module').then(
            (m) => m.VerburgPageModule,
          ),
      },
      {
        path: 'bakker-bloem',
        loadChildren: () =>
          import('../affiliates/bakker-page/bakker-page.module').then(
            (m) => m.BakkerPageModule,
          ),
      },
      {
        path: 'hypotheek-visie-meppel',
        loadChildren: () =>
          import('../affiliates/meppel-page/meppel-page.module').then(
            (m) => m.MeppelPageModule,
          ),
      },
      {
        path: 'not-found',
        loadChildren: () => import('../404-page/404-page.module').then((m) => m.NotFoundPageModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FrontPagesRoutingModule {}
